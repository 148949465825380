h1{
    color: white;
    font-family: 'AvenirBlack', sans-serif;
    font-size: 3.3vh;
    font-style: normal;
    text-align: left;
    text-transform: uppercase;
    margin: 0 0 -0.2em 4vw;
    font-weight: 100;
    line-height: 1;
}
.menu-icon{
    color: white;
    font-size: 2rem !important;
}
button.button-menu:hover {
    background: transparent !important;
}

.close-menu{
    display: flex !important;
    justify-content: flex-end !important;
}
button.close-menu:hover {
    background: transparent !important;
}
.close-menu span{
    max-width: fit-content;
}
.header-wrapper{
    display: flex;
    align-items: center;
    min-height: 6.8vh;
    background-color: #DB4343;
}
.title-container{
    display: flex;
}

.header-content{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.search {
    border-radius: 3em;
    flex-direction: row-reverse;
    align-items: center;
    padding: 0 0.8em;
    background-color: white;
    align-self: center;
    min-width: 13vw;
    height: 4vh !important;
}

.search button {
    border: none !important;
    color: var(--darkgray-etermax) !important;
    padding: 0 !important;
    height: 3.5vh !important;
}
.MuiInput-underline:before{
    border: none !important;
}
.MuiSelect-icon{
    color: white !important;
}
.search input {
    padding: 0 !important;
    height: fit-content !important;
    font-size: 1.7vh !important;
}


.menu-icon-container{
    display: flex;
    justify-content: center;
}

.close-icon {
    color: #fff;
}
.MuiDrawer-paper {
    background-color: var(--darkgray-etermax) !important;
}

.drawer-container{
    padding: 1em;
}
.select-language-desktop{
    color: white !important;
}
.select-language-desktop:after{
    border: none !important;
}
.close-menu span{
    margin: 0;
}

.select-language-mobile{
    display: none !important;
}

@media (max-width: 899px) {
    .header-content{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .select-language-mobile{
        display: flex !important;
        justify-content: center;
    }
    .select-language-desktop{
        display: none !important;
    }
    .select-language-mobile img{
        height: 4vh;
    }
    .align-flag{
        display: flex;
        align-items: center;
    }
    .MuiDrawer-paper{
        height: fit-content !important;
    }
    .social-buttons-mobile{
        display: flex;
        justify-content: space-evenly;
        width: 66%;
        margin: 3vh;
    }
    .search{
        margin-top: 3vh;
    }

}
@media (min-width: 900px) {
    .form-control{
        margin: 0 4vw 0 1.4vw !important;
    }
}
@media (max-width: 600px) {
    h1{
        font-size: 5vw;
    }
}
