.playable-wrapper{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 20vw;
    overflow: hidden;
    min-height: 56vh;
    max-height: 56vh;
    height: 100%;
    padding: 0;
    text-align: center;
    background-color: var(--darkgray-etermax);
    margin: 0 !important;
    border-radius: 1em;
}
.close-frame{
    position: absolute;
    z-index: 10;
    transform: translateY(-100%);
    right: 1vw;
    top: 6vh;
    cursor: pointer;
}
iframe {
    width: 100%;
    height: 100%;
    border: 0;
}

.image-wrapper{
    width: 100%;
    height: 45%;
}

.image-wrapper .card-image{
    height: 100%;
    background-position: center center;
    background-size: cover;
}

.playable-title{
    background-color: #161616;
    height: 4.4vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.playable-title h3{
    margin: 0;
    font-family: 'AvenirBlack', sans-serif;
    text-transform: uppercase;
    font-size: 1.1rem;
    color: #fff;
    font-weight: 800;
}
.playable-description{
    background-color: #DCDDDD;
    height: 14vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 1vw;
}
.playable-description p{
    font-size: 1.5vh;
    color: #181818;
    margin: 0;
    line-height: 1.2;
}

.success-case{
    font-size: 1.1vh;
    color: white;
    font-weight: 700;
    background-color: #181818;
    border-radius: 5em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5em 1.6em;
    text-decoration: none;
    text-transform: uppercase;
    margin-top: 1vh;
}
.success-case svg{
    font-size: 2vh;
    margin-right: 0.2vw;
}
p.examples{
    font-weight: 900;
    font-size: 1.3vh;
    margin: 0;
    color: #181818;
    background-color: #CDCDCD;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    padding: 0.5em 0;
    line-height: 1;
}
.examples-wrapper{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #fff;
    height: 10vh;
}
.slick-slide img{
    max-width: 8vw;
    max-height: 6vh;
    width: 8vw;
}
p.metrics{
    font-size: 1.2vh;
    margin: 0;
    background-color: #DCDDDD;
    color: #181818;
    height: 3.5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
}

#landscape{
    width: 35vw;
    height: 38vh;
}
.close-playable-btn{
    color: rgba(0, 0, 0, 0.85);
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 50%;
    cursor: pointer;
    font-size: 2.8vh !important;
    position: absolute;
    right: 1vw;
    top: 2vh;
}

.modal-iframe{
    height: 70vh;
    width: 25vw;
    border: transparent;
    outline: transparent;
}

@media (max-width: 820px) {
    .playable-wrapper{
        width: 45vw;
    }
    .modal-iframe{
        width: 70vw;
    }
    .slick-slide img {
        max-width: 100%;
        max-height: 100%;
    }
    #landscape{
        width: 95vw;
        height: 34vh;
    }
    .close-playable-btn{
        right: 3vw;
    }
}
@media (max-width: 600px) {
    .modal-iframe{
        width: 90vw;
    }
    .playable-wrapper {
        width: 75vw;
    }
    .playable-description {
        padding: 0 5vw;
    }
    .playable-description p {
        font-size: 3.5vw;
    }
    .modal-iframe{
        width: 90vw;
    }
    .playable-wrapper {
        width: 75vw;
    }
    .playable-description {
        padding: 0 5vw;
    }
    .playable-description p {
        font-size: 3.5vw;
    }
    .landscapeWrapper .close-playable-btn {
        right: 5vw;
        top: 2vh;
    }
    #landscape {
        height: 90vw;
        min-width: 70vh;
    }

}
