.terms-and-conditions{
    display: flex;
    justify-content: flex-end;
}
.rights-footer{
    display: flex;
    justify-content: flex-start;
}
.footer-terms-and-rights{
    background-color: #151719;
    padding: 0.8em;
}
.footer-terms-and-rights p, .footer-terms-and-rights a, .footer-terms-and-rights span{
    color: #fff;
    text-decoration: none;
    margin: 0;
    font-size: 0.8rem;
    text-transform: uppercase;
}
.terms-and-conditions span{
    margin: 0 0.5em;
}
.logo-BG-footer{
    width: 100%;
    height: 6vh;
}

footer {
    bottom: 0;
    width: 100%;
    background-color: var(--darkgray-etermax);
}
.social-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.footer-social-icon {
    width: 5vh;
    height: 5vh;
    background-size: cover;
    margin-left: 1vw;
}

.linkedin {
    background: url("../../assets/images/linkedin.svg") center;
}

.youtube {
    background: url("../../assets/images/youtube.svg") center;
}

.medium {
    background: url("../../assets/images/medium.svg") center;
}

.copyright {
    font-family: opensans;
    font-size: 14px;
    display: inline-table;
    width: initial;
    font-weight: normal;
    color: var(--lightgray-etermax);
    margin: 0;
}

.wrapper-footer{
    align-items: center;
    padding: 0 5%;
    justify-content: center;
}

.footer-lang .select-language{
    color: white !important;
}
.footer-lang .select-language:before{
    border-bottom-color: white !important;
}
.footer-lang .select-language svg{
    color: white !important;
}
.footer-lang .form-control{
    margin: 0 !important;
}
.span-footer{
    margin-left: 0.3em;
}

@media (max-width: 600px) {
    .terms-and-conditions{
        justify-content: center;
    }
    .rights-footer{
        justify-content: center;
    }
    .span-footer{
        display: none;
    }
    .footer-terms-and-rights p, .footer-terms-and-rights a, .footer-terms-and-rights span{
        font-size: 3vw;
    }
}
@media (min-width: 913px){
    .footer-lang{
        display: none;
    }
}

@media (max-width: 320px) {
    .footer-terms-and-rights {
        padding: 0.2em;
    }
    .footer-lang .select-language{
        font-size: 0.8rem !important;
    }
}

@media (min-width: 2880px){
    .footer-terms-and-rights p, .footer-terms-and-rights a, .footer-terms-and-rights span{
        font-size: 1.5rem;
    }
}
@media (max-width: 991px){
    .social-buttons-container{
       display: none;
    }
}
