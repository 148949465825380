:root {
    --lightgray-etermax: #e4e4e4;
    --red-etermax: #EE303B;
    --gray-etermax: #AEAFAF;
    --darkgray-etermax: #28292B;
    --violet-etermax: #624CBF;
    --orange-etermax: #F99F1B;
}

html, body {
    margin: 0;
    height: 100%;
    min-height: 100%;
    font-family: "Avenir", sans-serif;
}

body {
    display: flex;
    flex-direction: column;
}

header,
footer {
    flex: none;
}

main {
    overflow-y: auto;
    flex: auto;
}

p {
    color: black;
}

@font-face {
    font-family: 'gothamnarrowblack';
    src: url(fonts/GothamNarrow-Black.otf);
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'gothamnarrowbold';
    src: url(fonts/GothamNarrow-Bold.otf);
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "opensans";
    src: url(fonts/OpenSans-Regular.ttf);
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "opensans";
    src: url(fonts/OpenSans-Italic.ttf);
    font-style: italic;
    font-weight: normal;
}

@font-face {
    font-family: "opensansmedium";
    src: url(fonts/OpenSans-Semibold.ttf);
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "opensansmedium";
    src: url(fonts/OpenSans-SemiboldItalic.ttf);
    font-style: italic;
    font-weight: normal;
}

@font-face {
    font-family: "opensansbold";
    src: url(fonts/OpenSans-Bold.ttf);
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "opensansblack";
    src: url(fonts/OpenSans-ExtraBold.ttf);
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "Avenir";
    src: url(fonts/AvenirMedium.ttf);
    font-style: normal;
    font-weight: normal;
}
@font-face {
    font-family: "AvenirBlack";
    src: url(fonts/AvenirBlack.ttf);
    font-style: normal;
    font-weight: normal;
}
@font-face {
    font-family: "AvenirHeavy";
    src: url(fonts/AvenirHeavy.ttf);
    font-style: normal;
    font-weight: normal;
}
.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    justify-content: center;
    max-width: 70%;
    flex: 66%
}



.col {
    position: relative;
    width: 70%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px
}

/*HEADER*/
.App {
    background-color: var(--darkgray-etermax);
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-y: hidden;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}
.header-container{
    background-color: white;
    height: 8vh;
    flex-direction: row;
    align-content: center;
}
.header-container .title{
    display: flex;
    justify-content: center;
    color: var(--red-etermax);
    font-family: "AvenirBlack", sans-serif;
    font-weight: normal;
    font-size: 2em;
    font-style: normal;
    text-transform: uppercase;
    margin: 0;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.nav {
    top: 0;
    width: 100%;
    height: 90px;
}


span {
    content: '\007C';
    margin: 0 35px;
    font-size: x-large;
}

.container {
    align-items: center;
    font-size: calc(10px + 2vmin);
    text-align: center;
    text-align: -webkit-center;
    text-align: -moz-center;
    margin-top: 7vh;
    overflow-x: hidden;
}

main h3 {
    color: #fff;
    font-size: 36px;
    margin-top: 20px;
}


.slick-center {
    color: #e67e22;
    opacity: 1;
    -ms-transform: scale(1.2);
    transform: scale(1.2);
}

.slick-prev, .slick-next {
    color: #fff !important;
    border: 2px solid #fff !important;
    border-radius: 15px;
    transform: translate(25%, -50%) !important;
    display: none !important;
}

.slick-prev {
    left: -38px !important;
}

.slick-slide{
    height: 100%;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
}


.slick-track {
    overflow: hidden;
}

.slick-slider {
    height: 95%;
    width: 130%;
    margin-left: -15%;
}

.flip-front-card {
    -webkit-animation: flip-front-card 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
    animation: flip-front-card 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

@-webkit-keyframes flip-front-card {
    0% {
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
    }
    100% {
        -webkit-transform: rotateY(-180deg);
        transform: rotateY(-180deg);
    }
}

@keyframes flip-front-card {
    0% {
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
    }
    100% {
        -webkit-transform: rotateY(-180deg);
        transform: rotateY(-180deg);
    }
}

.flip-back-card {
    -webkit-animation: flip-back-card 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
    animation: flip-back-card 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

@-webkit-keyframes flip-back-card {
    0% {
        -webkit-transform: rotateY(0);
        transform: rotateY(180deg);
    }
    100% {
        -webkit-transform: rotateY(180deg);
        transform: rotateY(0);
    }
}

@keyframes flip-back-card {
    0% {
        -webkit-transform: rotateY(0);
        transform: rotateY(180deg);
    }
    100% {
        -webkit-transform: rotateY(180deg);
        transform: rotateY(0);
    }
}

.iframe-container iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    background-color: #e4e4e4;
}

.flip-playable {
    perspective: 1000px;
    border-radius: 1em;
    /*max-width: 30vw;*/
}

.flip-playable-inner {
    position: relative;
    width: 100%;
    height: auto;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    background-color: var(--darkgray-etermax);
}

.flip-action {
    transform: rotateY(180deg);
}

.flip-playable-front{
    height: 56vh;
}
.flip-playable-front, .flip-playable-back {
    position: absolute;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
}

.flip-playable-back {
    background-color: white;
    transform: rotateY(180deg);
    position: relative;
    display: inline-block;
    height: auto;
}

.slider-hide {
    opacity: 0;
    transition: visibility 0s, opacity .3s linear;
}

.slider-show {
    visibility: visible;
    opacity: 1;
}

.card-front-show {
    opacity: 1;
    transition: visibility 0s, opacity .3s linear;
}

.card-front-hide {
    visibility: visible;
    opacity: 0;
}

.footer-extra-info-container {
    font-family: "Avenir", sans-serif;
    font-size: 13px;
    background: #151719;
    width: 100%;
    height: 5vh;
    align-items: center;
    display: flex;
    flex-direction: row;
    color: white;
    text-transform: uppercase;
    justify-content: center;
}


.footer-extra-info-container a {
    color: white !important;
    text-decoration: none;
}

.footer-extra-info-item {
    padding-left: .5rem;
}

.header-sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
    padding: 1rem 2.5rem;
    background-color: white;
}

.header-sticky + .container {
    padding-top: 102px;
}

.hover-client-logo:hover {
    cursor: pointer !important;
    transform: scale(1.2);
    transition: transform .2s;
}

iframe {
    display: block;
    width: 100%;
    height: 65%;
    pointer-events: auto !important;
}
.arrow-slide-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: "Avenir", sans-serif;
    font-size: 2.3vh;
    font-weight: 800;
    color: #fff !important;
}
.arrow-slide-container svg.MuiSvgIcon-root{
    font-size: 3.5vh;
    cursor: pointer;
}
.card-opacity-right {
    mask-image: linear-gradient(to left, transparent 50%, black 100%);
}

.card-opacity-left {
    mask-image: linear-gradient(to right, transparent 50%, black 100%);
}

.slick-current {
    transition: all .5s ease-in-out;
}

.slick-list{
    height: 100% !important;
}
.slick-track{
    height: 100% !important;
}
@media (min-width: 900px) {
    .slick-list{
        overflow: visible;
    }
}
.MuiFormGroup-root{
    flex-direction: row !important;
}
.MuiFormControlLabel-root{
    width: 12vw;
    margin: 0 !important;
}
.MuiRadio-root{
    width: 8vw;
    color: #ffffff80 !important;
}
.MuiRadio-colorSecondary.Mui-checked{
    color: white !important;
}

.MuiDialog-paper.MuiPaper-rounded{
    border-radius: 2em;
}
.MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.7) !important;
}
.MuiDialog-scrollPaper {
    height: 92vh !important;
}
.MuiDialog-paperWidthSm {
    max-width: fit-content !important;
}
@media (max-width: 820px) {
    .MuiDialog-paper.MuiPaper-rounded{
        border-radius: 1em;
    }

    .slick-slider{
        width: 160%;
        margin-left: -30%;
    }
    .MuiDialog-paper{
        margin: 32px 0 12vh 0!important;
        overflow-x: hidden;
    }
    .MuiDialog-paperWidthSm{
        max-width: 100vw !important;
    }
}
@media (max-width: 600px) {
    .MuiDialog-scrollPaper {
        height: 96vh !important;
    }
    .arrow-slide-container>div{
        font-size: 2vh;
    }
    .landscapeWrapper .MuiDialog-paper{
        margin: 0 !important;
    }
    .landscapeWrapper .MuiDialog-paper {
        margin: 0 !important;
        min-width: 70vh;
    }
    .landscapeWrapper .MuiDialog-scrollPaper {
        transform: rotate(90deg);
        position: relative;
        height: 88vh !important;
        width: 100%;
    }
}
